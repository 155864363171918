import React from "react";
import "../App.css";
import emailIcon from "../assets/images/email.svg";

const Footer = () => {
  return (
    <div className="footer">
      <a href="mailto:mail@batuhanbalci.com" className="link">
        <img
          src={emailIcon}
          width="30"
          height="30"
          alt="email"
          style={{ opacity: 0.5 }}
        />
        <span>Email</span>
      </a>
    </div>
  );
};

export default Footer;
