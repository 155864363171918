import React from "react";
import avatar from "../assets/images/IMG_0844.PNG";

const Avatar = () => {
  return (
    <div
      style={{
        position: "relative",
        width: "150px",
        height: "150px",
        borderRadius: "50%",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 1)",
      }}
    >
      <img
        src={avatar}
        alt="batuhan balci"
        style={{
          width: "130px",
          height: "130px",
          borderRadius: "50%",
          border: "10px solid #fff",
          objectFit: "contain",
        }}
      />
    </div>
  );
};

export default Avatar;
