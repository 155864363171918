import React from "react";

const Link = ({ title, description, icon, link }) => {
  const redirect = () => (window.location.href = link);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: "1rem 3rem",
        gap: "2.5rem",
      }}
    >
      <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
          background:
            "linear-gradient(45deg, rgba(204,0,255,0.431) 0%, rgba(0,0,0,0.008) 100%)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "0% 0%",
          border: "2px solid rgba(255, 255, 255, 0.1)",
          cursor: "pointer",
        }}
        onClick={redirect}
      >
        <img src={icon} alt="link" width={100} height={100} />
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h2 onClick={redirect} style={{ cursor: "pointer" }}>
          {title}
        </h2>
        <p
          onClick={redirect}
          style={{ color: "rgba(255, 255, 255, 0.5)", cursor: "pointer" }}
        >
          {description}
        </p>
      </div>
    </div>
  );
};

export default Link;
