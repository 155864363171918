import React from "react";
import "../App.css";
import Avatar from "./Avatar.js";

const Header = () => {
  return (
    <div className="header">
      <div style={{ position: "relative" }}>
        <Avatar />
      </div>
      <h1>Batuhan Balcı</h1>
      <span>Software Engineer</span>
    </div>
  );
};

export default Header;
