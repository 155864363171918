import React from "react";
import "./App.css";
import Footer from "./components/Footer.js";
import Header from "./components/Header.js";
import LinkContainer from "./components/LinkContainer.js";

const App = () => {
  return (
    <main className="main">
      <div className="card">
        <Header />
        <LinkContainer />
        <Footer />
      </div>
    </main>
  );
};

export default App;
