import React from "react";
import "../App.css";
import githubLogo from "../assets/images/github2.svg";
import linkedinLogo from "../assets/images/linkedin2.svg";
import Link from "./Link.js";

const LinkContainer = () => {
  return (
    <div>
      <Link
        title="Linkedin"
        description="My Linkedin Profile"
        icon={linkedinLogo}
        link="https://www.linkedin.com/in/batuhanbalci"
      />
      <hr className="divider" />
      <Link
        title="Github"
        description="My Github Profile"
        icon={githubLogo}
        link="https://github.com/batuhanbalci"
      />
    </div>
  );
};

export default LinkContainer;
